<template>
    <!-- <b-row no-gutters> -->
    <v-app-bar color="appbar_color" fixed app elevate-on-scroll elevation="1" dense>
      <div class="w-100 d-flex align-items-center my-2">

        <div class="d-flex flex-column justify-content-center flex-fill">
          <span v-if="helloActive" class="f15 font-weight-bold text-info">{{ $t('Hello!') }}</span>
          <span v-if="helloActive" class="f11 font-weight-bold text-info">{{ fullName }}</span>

          <v-btn icon v-if="backActive && $vuetify.rtl" @click="goBack" small  color="icon_color">
            <iconly type="light" name="arrow-right" size="2x"/>
          </v-btn>
          <v-btn icon v-if="backActive && !$vuetify.rtl" @click="goBack" small color="icon_color">
            <iconly type="light" name="arrow-left" size="2x"/>
          </v-btn>
        </div>

        <v-toolbar-title class="f15 fw-bold flex-fill text-center">
          <span v-if="toolbarTitleActive">{{ toolbarTitle }}</span>
        </v-toolbar-title>

        <div class="d-flex flex-fill justify-content-end">

          <v-btn icon small v-if="notificationActive" color="icon_color" to="/c/notifications">
            <v-badge left overlap bordered color="red" class="lh-18" :content="notificationCount" :value="notificationCount">
              <iconly type="light" name="notification" size="2x"/>
            </v-badge>
          </v-btn>

          <v-menu v-if="threeDotsActive" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="text-center" color="icon_color">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in threeDotsMenu"
                :key="i"
                link
                :to="item.to"
                @click="item.click"
              >
                <span>{{ $t(item.title) }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

    </v-app-bar>
</template>

<script>

export default {
  name: "TheHeader",
  data() {
    return {
      notificationActive: this.$store.getters.notificationActive,
      threeDotsActive: this.$store.getters.threeDotsActive,
      threeDotsMenu: this.$store.getters.threeDotsMenu,
      helloActive: this.$store.getters.helloActive,
      backActive: this.$store.getters.backActive,

      toolbarTitle: this.$route.meta.title ? this.$route.meta.title : "",
      toolbarTitleActive: this.$store.getters.toolbarTitleActive,
      fullName: this.$store.getters.fullName,

      notificationCount: this.$store.getters.notificationCount,

    };
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    },
  },
  watch: {
    '$store.getters.notificationActive': function () {
      this.notificationActive = this.$store.getters.notificationActive
    },
    '$store.getters.threeDotsActive': function () {
      this.threeDotsActive = this.$store.getters.threeDotsActive
    },
    '$store.getters.threeDotsMenu': function () {
      this.threeDotsMenu = this.$store.getters.threeDotsMenu
    },
    '$store.getters.helloActive': function () {
      this.helloActive = this.$store.getters.helloActive
    },
    '$store.getters.backActive': function () {
      this.backActive = this.$store.getters.backActive
    },
    '$route.meta.title': function () {
      this.toolbarTitle = this.$route.meta.title
    },
    '$store.getters.toolbarTitleActive': function () {
      this.toolbarTitleActive = this.$store.getters.toolbarTitleActive
    },
    '$store.getters.fullName': function () {
      this.fullName = this.$store.getters.fullName
    },

    '$store.getters.notificationCount': function () {
      this.notificationCount = this.$store.getters.notificationCount
    },
  }
};
</script>