<template>
  <v-footer app color="transparent" padless>
    <v-bottom-navigation color="primary" fixed dark mandatory background-color="#232126" grow app class="rounded-t-xl" height="64">
      <v-btn to="/c/home" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Home')}}</span>
        <iconly name="home" type="light" size="2x" />
      </v-btn>

      <v-btn to="/c/explore" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Explore')}}</span>
        <iconly name="category" type="light" size="2x" />
      </v-btn>

      <v-btn to="/c/search" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Search')}}</span>
        <iconly name="search" type="light" size="2x" />
      </v-btn>

      <v-btn to="/c/moods" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Mood status')}}</span>
        <iconly name="activity" type="light" size="2x" />
      </v-btn>

      <v-btn to="/c/profile" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Profile')}}</span>
        <iconly name="profile" type="light" size="2x" />
      </v-btn>
    </v-bottom-navigation>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
    };
  },
  methods: {},
};
</script>
